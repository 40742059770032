@import '~@apto/aptotude/theme/variables.scss';

html {
  font-size: 62.5% !important;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: $font-size-base !important;
  font-weight: $font-weight-base !important;
  line-height: $line-height-base !important;
  font-family: $font-family-base !important;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
