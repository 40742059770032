@media print {
  .Template1 {
    .Hero {
      height: auto;
      min-height: auto;
      color: #000;
      text-shadow: none;
      .Hero-PrintImage {
        display: block;
        float: right;
      }
    }

    .AptoContainer {
      clear: both;
      padding-top: 2rem;
    }

    .HeroInfo {
      position: static;
    }

    .HeroPrice {
      margin-top: 2rem;
      position: static;
    }

    .leftColumn,
    .rightColumn {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .LeadForm {
      display: none !important;
    }

    .AptoCard {
      box-shadow: none;
    }
  }
}
