.Hero {
  .Hero-PrintImage {
    display: none;
  }
}

@media print {
  .Hero {
    background: none !important;
  }
}
