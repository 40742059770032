@import '~@apto/aptotude/theme/variables.scss';

$blue: #1f4f80;

.Template1 {
  .Hero {
    min-height: 400px;
    height: 65vh;
    color: $white;
    position: relative;
    margin-bottom: 3rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    background-color: $gray-300;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @media screen {
      &:after {
        content: '';
        background: linear-gradient(
          360deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(255, 255, 255, 0) 70%,
          rgba(255, 255, 255, 0) 100%
        );
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .AptoContainer {
      height: 100%;
    }
  }

  .HeroInfo,
  .HeroPrice {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 1rem;
    z-index: 2;
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      width: auto;
      padding: 0;
    }
  }

  .HeroInfo {
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    .AptoHeader {
      font-weight: $font-weight-light;
      font-size: 3rem;
    }
    address {
      font-weight: $font-weight-light;
      font-size: 2rem;
      font-style: normal;
    }
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      background: none;
      bottom: 1rem;
      top: auto;
      left: 10px;
      .AptoHeader {
        font-size: 5rem;
      }
      address {
        font-size: 3.5rem;
      }
    }
  }

  .HeroPrice {
    bottom: 0;
    font-size: 1.8rem;
    .HeroPrice-price {
      font-weight: $font-weight-semibold;
      font-size: 2rem;
    }
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      right: 10px;
      left: auto;
      bottom: 1rem;
      .HeroPrice-price {
        font-size: 3.5rem;
      }
    }
  }

  .Description .AptoHeader,
  .ListingData .AptoHeader {
    color: $blue;
    margin-bottom: 1rem;
  }

  .Description,
  .ListingData {
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding-right: 2rem;
    }
  }

  .ListingData {
    margin-top: 2rem;
    .AptoCol {
      margin-bottom: 2rem;
    }
    .ListingData-label {
      color: $gray-500;
      font-weight: $font-weight-light;
      text-transform: capitalize;
    }
  }

  .LeadForm {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: $blue;
    .AptoCardHeader {
      color: $white;
      font-size: $h1-font-size;
      font-weight: $font-weight-bold;
    }
    .AptoButton {
      width: 100%;
      background-color: $blue;
      color: $white;
    }
    .ui.form {
      textarea,
      input {
        color: $white !important;
        border-bottom: 1px solid $white;
        background: transparent !important;
        &:focus {
          border-bottom: 1px solid darken($white, 30%) !important;
        }
      }
      .field {
        > label {
          color: $white !important;
        }
        &.required > label:after {
          color: $white;
        }
      }
    }
    .grecaptcha-badge {
      margin: 2rem auto 0 auto;
      box-shadow: none !important;
    }
  }

  .BrokerProfile,
  .CompanyProfile {
    img {
      width: 115px;
      height: auto;
    }
    .AptoHeader {
      margin-top: -4px;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0.2rem 0 0 0;
        padding: 0;
      }
    }
    color: $gray-600;
    font-size: $font-size-md;
  }
}
